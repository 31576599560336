import Flickity from 'flickity';
import '../scss/index.scss';
import 'flickity/css/flickity.css';
import {bindImageZoom, setCurrentOpenedImage} from './carousel';

const selectors = {
    btnOrder: '.j-btn-order',
    carousel: 'certificate-carousel',
    headerLink: '.header__link',
    main: '.main',
    header: '.header',
    section: '.section',
    noticeCloseBtn: '.notice__close',
    notice: '.notice',
    headerMenuContainer: '.header__menu-container',
    headerMenuClose: '.header__menu-close',
    headerLinkMobile: '.header__link-mobile-icon',
};

document.addEventListener('DOMContentLoaded', function() {
    setActiveTab();
    bind();
    noteInit();
    renderSprite();
});

function bind() {
    const carousel = document.getElementById(selectors.carousel);
    const headerLinkMobile = document.querySelector(selectors.headerLinkMobile);

    headerLinkMobile.removeEventListener('click', handleMobileMenuClick);
    headerLinkMobile.addEventListener('click', handleMobileMenuClick);

    initCarousel();
    bindImageZoom(carousel);
}

function initCarousel() {
    const flickity = new Flickity('.certificate-carousel', {
        wrapAround: true,
        freeScroll: true,
        draggable: false,
        resize: true,
        contain: true,
    });

    flickity.on('change', handleFlickityOnChange);
}

function handleFlickityOnChange() {
    const currentImageContainer = document.getElementsByClassName('certificate-carousel__cell is-selected')[0];
    const currentImage = currentImageContainer.firstElementChild;

    setCurrentOpenedImage(currentImage);
}

function handleMobileMenuClick() {
    const menu = document.querySelector(selectors.headerMenuContainer);
    const body = document.querySelector('body');
    const main = document.querySelector(selectors.main);
    const menuClose = document.querySelector(selectors.headerMenuClose);
    const header = document.querySelector(selectors.header);

    menu.classList.toggle('active');
    body.classList.toggle('header-open');
    header.classList.toggle('header__backdrop');

    if (menu.classList.contains('active')) {
        menuClose.removeEventListener('click', hideMobileMenu);
        menuClose.addEventListener('click', hideMobileMenu);
        main.removeEventListener('click', hideMobileMenu);
        main.addEventListener('click', hideMobileMenu);
    } else {
        main.removeEventListener('click', hideMobileMenu);
    }
}

function hideMobileMenu() {
    const header = document.querySelector(selectors.header);
    const menu = document.querySelector(selectors.headerMenuContainer);
    const body = document.querySelector('body');

    header.classList.remove('header__backdrop');
    menu.classList.remove('active');
    body.classList.remove('header-open');
}

function setActiveTab() {
    const index = `/${document.location.pathname.split('/')[1]}`;
    const headerLink = document.querySelector(`${selectors.headerLink}[href="${index}"]`);
    if (headerLink) {
        headerLink.classList.add('active');
    }
}

function noteInit() {
    // const isHiddenNote = localStorage.getItem('isHiddenNote');

        document.querySelectorAll(selectors.notice).forEach((notice) => notice.classList.remove('hidden'));
        document.querySelectorAll(selectors.noticeCloseBtn).forEach((closeBtn) => {
            closeBtn.removeEventListener('click', hideNotice);
            closeBtn.addEventListener('click', hideNotice);
        })
}

function hideNotice() {
    // localStorage.setItem('isHiddenNote', '1');
    document.querySelectorAll(selectors.notice).forEach((notice) => notice.classList.add('hidden'));
}

function renderSprite() {
    const object = document.createElement('object');

    object.type = 'image/svg+xml';
    object.data = '/img/icons/sprite/sprite.svg';
    object.onload = handleSpriteObjectLoad;
    document.body.appendChild(object);
}

function handleSpriteObjectLoad() {
    const svgSprite = this.contentDocument.firstElementChild;
    svgSprite.style.display = 'none';
    document.body.appendChild(svgSprite);
    this.remove();
}